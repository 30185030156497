<template>
  <create-playlist :loggedUser="loggedUser" />
</template>

<script>
import { CreatePlaylist } from '../components'

export default {
  name: 'Createplaylist',

  components: {
    CreatePlaylist
  },

  computed: {
    loggedUser() {
      return this.$store.state.auth.loggedUser
    }
  }
}
</script>

<style></style>
